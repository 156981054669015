import Project1000gal from "./Proj1000gal";
// import "./App.css";
import './main.css';
function App() {
  return (
    <div className="App">
      <Project1000gal/>
    </div>
  );
}

export default App;
